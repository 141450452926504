<template>
  <footer class="text-muted">
      <div class="container">
        <p class="float-right">
          <a href="#">回到顶部</a>
        </p>
        <p>欢迎使用线视频课程系统，程序员的梦工厂，致力于全栈技术推广</p>
        <a target="_blank" href="https://beian.miit.gov.cn">津ICP备2022007587号</a>
      </div>
    </footer>
</template>

<script>
export default {
  name: "theFooter"
}
</script>

<style scoped>

</style>